import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Numbers from "../components/numbers"
import Piloni from "../components/piloni"
import Slider from "../components/slider"

import DownloadIcon from "../images/download.svg"

// heros
import HeroViitorul from "../images/hero-viitorul.jpg"

// heros mobile
import HeroViitorulM from "../images/hero-viitorul-m.jpg"

import Submenu from "../components/submenu"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Acasa" />
    <section className="bg-light d-none">
      <div className="hero text-center mx-auto">
        <img className="d-none d-lg-block w-100" src={HeroViitorul} alt="" />
        <img className="d-lg-none w-100" src={HeroViitorulM} alt="" />
      </div>
    </section>
    <Slider home="1" />
    <Submenu />
    <section className="about-section bg-light py-5 mb-5">
      <div className="container py-5">
        <div className="row mb-4 justify-content-center">
          <div className="col-12 col-md-9 text-center">
              <h1 className="text-success akko-bold py-3">
                Despre Sustenabilitate
              </h1>
              <p>
                Sustenabilitatea face parte din identitatea noastră. Fără
                dezvoltare durabilă nu le putem lăsa copiilor noștri un viitor de
                care să fie mândri. Suntem încrezători că vom putea continua toate
                proiectele începute în zona sustenabilității și vom identifica
                arii noi in care ne putem aduce contribuția.
              </p>
              <p>
                În acest context, protejarea oamenilor, animalelor și a mediului
                sunt zone esențiale pe care vom pune accentul în perioada
                următoare, astfel încât contribuția noastră să se simtă în mod
                real la nivelul întregii societăți.
              </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-6 align-self-center">
            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Pz89tuxBnPE?si=uly8IoX1YurN2AVV"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/yLdHsNDauzI?si=eWvFpApetm_WaIuW"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 align-self-center pb-4">
            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/5p4nj3ShDw4?si=G6F8pIdG2pbcmj-G"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
          <div className="col-12 col-md-6 pb-4">
            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/XT-x30AgSf4?si=_AAr7rJKlu9EipA6"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap text-center pt-5">
          <a
            href="/raport-de-sustenabilitate-2023"
            className="btn btn-primary akko-bold m-2"
          >
            Descarca raport RO
            <img className="icon-badge-white ml-2" src={DownloadIcon} alt="" />
          </a>
          <a
            href="/raport-de-sustenabilitate-2023-en"
            className="btn btn-primary akko-bold m-2"
          >
            Descarca raport EN
            <img className="icon-badge-white ml-2" src={DownloadIcon} alt="" />
          </a>
        </div>
      </div>
    </section>

    <div className="container">
      <Piloni />
      {/* raport */}
      <div className="raport-container my-5">
        <div className="row">
          <div className="col-10 my-5 mx-auto">
            <h1 className="text-success text-center akko-bold py-3">
              Rapoarte Sustenabilitate
            </h1>
            <p className="akko-regular text-center">
              Ne propunem să ne atingem obiectivele prin investiții planificate
              pentru excelența operațională și prin elaborarea de soluții
              inovatoare pentru clienți, furnizori, parteneri și comunități. În
              următorii ani, vom continua să lucrăm umăr la umăr pentru
              integrarea practicilor sustenabile pe întregul lanț valoric.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-md">
            <a
              className="btn btn-success btn-lg akko-bold w-100 rounded-1 d-flex justify-content-center align-items-center mb-3"
              href="raport-de-sustenabilitate-2017"
            >
              2017
            </a>
          </div>
          <div className="col-4 col-md">
            <a
              className="btn btn-success btn-lg akko-bold w-100 rounded-1 d-flex justify-content-center align-items-center mb-3"
              href="/raport-de-sustenabilitate-2018"
            >
              2018
            </a>
          </div>
          <div className="col-4 col-md">
            <a
              className="btn btn-success btn-lg akko-bold w-100 rounded-1 d-flex justify-content-center align-items-center mb-3"
              href="/raport-de-sustenabilitate-2019"
            >
              2019
            </a>
          </div>
          <div className="col-4 col-md">
            <a
              className="btn btn-success btn-lg akko-bold w-100 rounded-1 d-flex justify-content-center align-items-center mb-3"
              href="/raport-de-sustenabilitate-2020"
            >
              2020
            </a>
          </div>
          <div className="col-4 col-md">
            <a
              className="btn btn-success btn-lg akko-bold w-100 rounded-1 d-flex justify-content-center align-items-center mb-3"
              href="/raport-de-sustenabilitate-2021"
            >
              2021
            </a>
          </div>
          <div className="col-4 col-md">
            <a
              className="btn btn-success btn-lg akko-bold w-100 rounded-1 d-flex justify-content-center align-items-center mb-3"
              href="/raport-de-sustenabilitate-2022"
            >
              2022
            </a>
          </div>
          <div className="col-4 col-md">
            <a
              className="btn btn-success btn-lg akko-bold w-100 rounded-1 d-flex justify-content-center align-items-center mb-3"
              href="/raport-de-sustenabilitate-2023"
            >
              2023
            </a>
          </div>
        </div>
      </div>
    </div>

    <Numbers />
  </Layout>
)

export default IndexPage
